import "../styles/Menu.css";

import LogoLeco from "../assets/icones/logo_vert.png";
import React, { useState } from "react";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

/*
const links = [
  { href: '#home', text: 'Home' },
  { href: '#card', text: 'Product' },
  { href: '#about', text: 'About' },
  { href: '#cata', text: 'Categories' },
  { href: '#test', text: 'Blogs' },
  { href: '#test2', text: 'News' },
  { href: '#busns', text: 'Adds', className: 'btnadd' },
  { href: '/login', text: 'LOGIN' },
];*/

const createNavItem = ({ id, href, className }) => (
  <NavItem key={id}>
    <NavLink href={"#" + href} className={className}>
      {id}
    </NavLink>
  </NavItem>
);

function Menu({ menuList, deployed, setDeployed }) {
  const toggle = () => setDeployed(!deployed);

  /*[key: string]: any;
  light?: boolean;
  dark?: boolean;
  full?: boolean;
  fixed?: string;
  sticky?: string;
  color?: string;
  container?: boolean | 'fluid' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  tag?: React.ElementType;
  cssModule?: CSSModule;
  expand?: boolean | string;
  const args = [ 'light' : true, ];*/

  return (
    <div className="Menu">
      <Navbar color="light" light expand="lg">
        <NavbarBrand href="/">
          <img src={LogoLeco} alt="logo-leco" className="logo-leco" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse id="menuNavBar" isOpen={deployed} navbar>
          <Nav className="menu-navbar" navbar>
            {menuList.map(createNavItem)}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Menu;
