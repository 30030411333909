import "../styles/QuiSommesNous.css";

import LecoAssocies from "../assets/icones/leco_associes.png";

import { Container } from "reactstrap";
import Partenaires from "./Partenaires";

function QuiSommesNous() {
  return (
    <Container className="QuiSommesNous">
      <div className="title color-orange">Qui sommes-nous ?</div>
      <div className="content">
        <div className="text-content">
          <div className="text color-grey">
            <span className="color-blue">16 années</span>
            <br /> que nous travaillons ensemble afin de vous apporter les
            solutions numériques indispensables au management des équipes de
            conducteurs routiers. Avec pour mission première d’accompagner les
            axes d’économie tel que la consommation de carburant, mais aussi la
            prévention à travers la sinistralité, l’assiduité, et l’état des
            véhicules en concordance avec l’image de votre entreprise. Il nous
            tient à cœur de fidéliser votre conducteur dans un environnement
            favorable à son épanouissement professionnel, tout en participant à
            améliorer son pouvoir d’achat.
          </div>
        </div>
        <div className="photo-content">
          <div>
            <img className="associe" alt="associe" src={LecoAssocies} />
          </div>
          <div className="photo-item-names color-grey">
            <div className="photo-item-name">
              Vincent Deliencourt<span>Directeur Général</span>
            </div>
            <div className="photo-item-name ">
              Vincent Barremaecker<span>Président</span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default QuiSommesNous;
