import "../styles/App.css";
import "../assets/fonts/ink-free.ttf";

import GoTop from "../components/GoTop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import OnePage from "../pages/OnePage";

import CookieConsent from "./CookieConsent.js";

import LogoLeco from "../assets/icones/logo_leco_PNG.png";
import FondDecoupe from "../assets/fonds/fond_decoupe.webp";
import LogoCEE from "../assets/icones/icone_cee.png";
import LogoEngie from "../assets/icones/engie_black.png";

import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Alert,
  Row,
  Col,
  Form,
  Label,
  Input,
} from "reactstrap";
import { useState, useEffect } from "react";
import Solution from "../pages/Solution.js";
import Contact from "../pages/Contact.js";
import QuiSommesNous from "../pages/QuiSommesNous.js";
import Connexion from "../pages/Connexion.js";
import Tarif from "../pages/Tarif.js";
import MergePages from "../pages/MergePages.js";
import CheckIcon from "@mui/icons-material/Check";

function App() {
  const [showGoTop, setshowGoTop] = useState(false);

  const [showMentions, setShowMentions] = useState(false);
  const [showCEE, setShowCEE] = useState(false);
  const [deployed, setDeployed] = useState(false);

  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessageTitle, setAlertMessageTitle] = useState("");
  const [alertMessageBody, setAlertMessageBody] = useState("");
  const [alertMessageOkFunction, setAlertMessageOkFunction] = useState(
    () => () => {}
  );

  const { innerWidth: winWidth, innerHeight: winHeight } = window;
  var otherWinHeight = winWidth < 768 ? winHeight * 0.7 : winHeight;

  //SCROLL UP HANDLER
  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //SCROLL LISTENER
  useEffect(() => {
    //DISPLAY HANDLER
    const handleVisibleButton = () => {
      window.pageYOffset >= winHeight
        ? setshowGoTop(true)
        : setshowGoTop(false);
      setDeployed(false);
    };

    window.addEventListener("scroll", handleVisibleButton);
    return () => {
      window.removeEventListener("scroll", handleVisibleButton);
    };
  }, [winHeight]);

  useEffect(() => {
    //DISPLAY HANDLER
    const handleClickOutside = () => {
      if (
        document.getElementById("menuNavBar") &&
        deployed &&
        document.activeElement.className !== "navbar-toggler"
      ) {
        setDeployed(false);
      }
    };
    // Bind the event listener
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [deployed]);

  const menuList = [
    {
      id: "La solution",
      href: "solution",
      page: <Solution />,
    },
    { id: "Connexion", href: "connexion", page: <Connexion /> },
    {
      id: "Tarif / CEE",
      href: "tarif",
      page: (
        <MergePages
          showMentions={showMentions}
          setShowMentions={setShowMentions}
          showCEE={showCEE}
          setShowCEE={setShowCEE}
        />
      ),
      customStyle: {
        background: `url(${FondDecoupe})`,
        backgroundSize: "cover",
        backgroundPosition: "left top",
      },
    },
    { id: "Qui sommes-nous ?", href: "quisommesnous", page: null },
    { id: "Contact", href: "contact", page: null },
  ];

  const handleSendCEE = (e) => {
    //alert("handleSendCEE");

    e.preventDefault();
    fetch(
      "https://app.leco-france.eu/api/sendCEESimulation?" +
        "&effectif=" +
        e.target.effectif.value +
        "&km=" +
        e.target.km.value +
        "&moyenne=" +
        e.target.moyenne.value +
        "&entreprise=" +
        e.target.entreprise.value +
        "&nom=" +
        e.target.nom.value +
        "&prenom=" +
        e.target.prenom.value +
        "&tel=" +
        e.target.tel.value +
        "&mail=" +
        e.target.email.value
    )
      .then((results) => results.json())
      .then((data) => {
        console.log(data);
        if (data.return === "ok") {
          setAlertMessageBody(
            "Votre simulation a bien été enregistrée.\nVous serez prochainement contacté par nos équipes pour vous présenter la solution Léco et le financement CEE.\nBonne route !"
          );
          setAlertMessageTitle("Simulation enregistrée");

          setAlertMessageOkFunction(() => () => {
            e.target.reset();
            window.location.hash = "";
            setShowCEE(false);
            setShowAlertMessage(false);
          });
          setShowAlertMessage(true);
        } else {
          setAlertMessageBody(
            "Une erreur est survenue lors de la demande de simulation. Veuillez réessayer plus tard."
          );
          setAlertMessageTitle("Erreur");
          setAlertMessageOkFunction(() => () => {
            setShowAlertMessage(false);
          });
          setShowAlertMessage(true);
        }
      });
  };

  return (
    <Container className="App">
      <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp} />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <OnePage
                menuList={menuList}
                deployed={deployed}
                setDeployed={setDeployed}
                showMentions={showMentions}
                setShowMentions={setShowMentions}
                winHeight={winHeight}
                otherWinHeight={otherWinHeight}
              />
            }
          />
          <Route
            exact
            path="/PrivacyPolicy"
            element={
              <PrivacyPolicy
                deployed={deployed}
                setDeployed={setDeployed}
                winHeight={winHeight}
              />
            }
          />
        </Routes>
      </Router>

      <CookieConsent
        message="Dans le but de vous accueillir au mieux, notre site utilise des cookies. En poursuivant votre navigation, vous acceptez leurs utilisations."
        buttonLabel="Accepter"
        logoSrc={LogoLeco}
        showMentions={showMentions}
        setShowMentions={setShowMentions}
      />

      <Modal isOpen={showCEE} className="modal-cee">
        <ModalHeader>
          <img className="logo-cee" src={LogoCEE} alt="icon-cee" />
        </ModalHeader>
        <ModalBody>
          <div className="modal-container">
            <div className="title">Financement CEE</div>
            <div className="subtitle">Prise en charge partielle</div>
            <div className="demarche-title">La démarche</div>
            <div className="demarche-items">
              <div className="demarche-item">
                <CheckIcon className="color-green" />
                <div>Estimation du financement CEE (Offerte)</div>
              </div>
              <div className="demarche-item">
                <CheckIcon className="color-green" />
                <div>
                  Accompagnement sur la convention CEE par CertiNergy &
                  Solutions (filiale d'ENGIE)
                </div>
              </div>
              <div className="demarche-item">
                <CheckIcon className="color-green" />
                <div>Déploiement du programme Léco</div>
              </div>
            </div>
            <Form className="simulateur-container" onSubmit={handleSendCEE}>
              <div className="title-container">
                <div className="title-text">
                  <div className="title">Simulateur CEE</div>
                  <div className="subtitle">Renseignez vos informations</div>
                </div>
                <div className="title-img">
                  <img src={LogoEngie} alt="icon-engie" />
                </div>
              </div>
              <Container className="form-items">
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    <Label>Effectif conducteur</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input type="text" id="effectif" required />
                  </Col>
                </Row>
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    <Label>Kilométrage N-1</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Input type="text" id="km" required />
                  </Col>
                </Row>
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    {" "}
                    <Label>Moyenne conso N-1</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    {" "}
                    <Input type="text" id="moyenne" required />
                  </Col>
                </Row>
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    {" "}
                    <Label>Entreprise</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    {" "}
                    <Input type="text" id="entreprise" required />
                  </Col>
                </Row>
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    {" "}
                    <Label>Nom</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    {" "}
                    <Input type="text" id="nom" required />
                  </Col>
                </Row>
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    {" "}
                    <Label>Prénom</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    {" "}
                    <Input type="text" id="prenom" required />
                  </Col>
                </Row>
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    {" "}
                    <Label>Téléphone</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    {" "}
                    <Input type="text" id="tel" required />
                  </Col>
                </Row>
                <Row className="form-item">
                  <Col xs={12} sm={6}>
                    {" "}
                    <Label>Mail</Label>
                  </Col>
                  <Col xs={12} sm={6}>
                    {" "}
                    <Input type="text" id="email" required />
                  </Col>
                </Row>

                <Row className="form-val-butt">
                  <Col className="cee-simui-footer" xs={12} sm={10}>
                    Obtention du financement CEE à 12 mois
                    <br />
                    sur résultats du programme.
                  </Col>
                  <Col
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className="ml-butt-valid" type="submit">
                      Envoyer
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="ml-butt-valid" onClick={() => setShowCEE(false)}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showAlertMessage} className="alert-message-modal">
        <ModalHeader
          style={{
            whiteSpace: "pre-line",
            justifyContent: "center",
            textAlign: "center",
          }}>
          {alertMessageTitle}
        </ModalHeader>
        <ModalBody style={{ whiteSpace: "pre-line", textAlign: "center" }}>
          {alertMessageBody}
        </ModalBody>
        <ModalFooter className="form-val-butt">
          <Button className="ml-butt-valid" onClick={alertMessageOkFunction}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default App;
