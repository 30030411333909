import "../styles/App.css";
import ReferencedContent from "../components/ReferencedContent";
import Home from "../pages/Home";
import Footer from "../pages/Footer";

import { Container, Row, Col } from "reactstrap";

function OnePage({
  menuList,
  deployed,
  setDeployed,
  showMentions,
  setShowMentions,
  winHeight,
  otherWinHeight,
}) {
  const shadowLine = "0 4px 10px 4px #CCC";
  /*
  @media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    }
}
@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
}
@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
}*/
  /*
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);*/

  return (
    <Container className="Home">
      <Col>
        <Row style={{ minHeight: winHeight }}>
          <Home
            menuList={menuList}
            deployed={deployed}
            setDeployed={setDeployed}
          />
        </Row>
        {menuList.map((menu) => {
          let customStyle = {};
          if (menu.customStyle !== undefined)
            customStyle = { ...customStyle, ...menu.customStyle };
          //console.log(customStyle);
          return menu?.page !== null ? (
            <Row
              key={menu.href}
              style={{
                minHeight: otherWinHeight,
                boxShadow: shadowLine,
              }}>
              <ReferencedContent id={menu.href} customeStyle={customStyle}>
                {menu.page}
              </ReferencedContent>
            </Row>
          ) : null;
        })}
      </Col>
    </Container>
  );
}

export default OnePage;
