import "../styles/Home.css";
import LeaderIco from "../assets/icones/leader.png";
import bg from "../assets/fonds/leco_fond_accueil_PNG25.webp";
import Menu from "../components/Menu";

import {
  Container,
  Row,
  Col /*, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem*/,
} from "reactstrap";

function Home({ menuList, deployed, setDeployed }) {
  return (
    <Container
      className="Home"
      style={{
        background: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundOrigin: "center center",
      }}
    >
      <div className="content">
        <Row>
          <Menu
            menuList={menuList}
            deployed={deployed}
            setDeployed={setDeployed}
          />
        </Row>
        <Row>
          <Row>
            <Col className="title">
              <p>Logiciels CRM</p>
              Éco-conduite & Prévention
            </Col>
          </Row>
          <Row>
            <Col className="sub-title">
              Transports marchandises et voyageurs
            </Col>
          </Row>
        </Row>
      </div>
    </Container>
  );
}

export default Home;
