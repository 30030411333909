import "../styles/Contact.css";
import React, { useState } from "react";

import IconPhoneLeco from "../assets/icones/icon_phone_leco.png";
import IconConnexion from "../assets/icones/icon_go.png";

import {
  Container,
  Modal,
  Form,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

function Contact() {
  const [nomPrenom, setNomPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");

  const [nomPrenomInvalid, setNomPrenomInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [messageInvalid, setMessageInvalid] = useState(false);

  // Modal open state
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  // Toggle for Modal
  const [sendRequest, setSendRequest] = useState(false);

  React.useEffect(() => {
    if (sendRequest) {
      const toggle = () => setModal(!modal);

      if (nomPrenom && email && message) {
        const htmlContent = encodeURIComponent(
          document.getElementById("message").value
        );
        console.log("Send : " + htmlContent);
        /*console.log(message);*/
        /*console.log(
          "https://app.leco-france.eu/send_contact/" +
            nomPrenom +
            "/" +
            email +
            "/" +
            (tel ? tel : " ") +
            "/" +
            (entreprise ? entreprise : " ") +
            "/0/" +
            htmlContent
        );*/
        //fetch("https://app.leco-france.eu/send_contact?name="+nomPrenom+"&email="+email+"&tel="+tel+"&entreprise="+entreprise+"&typeContact=0&message="+htmlContent)
        fetch(
          "https://app.leco-france.eu/send_contact/" +
            nomPrenom +
            "/" +
            email +
            "/" +
            (tel ? tel : " ") +
            "/" +
            (entreprise ? entreprise : " ") +
            "/0/" +
            htmlContent
        )
          .then((results) => results.json())
          .then((data) => {
            console.log(data);
            setNomPrenomInvalid(false);
            setEmailInvalid(false);
            setMessageInvalid(false);
            toggle(true);
            setNomPrenom("");
            setEmail("");
            setEntreprise("");
            setTel("");
            setMessage("");
            window.location.hash = "";
          });
      } else {
        setNomPrenomInvalid(!nomPrenom);
        setEmailInvalid(!email || !validEmail(email));
        setMessageInvalid(!message);
      }
      setSendRequest(false);
    }
  }, [sendRequest, nomPrenom, email, tel, entreprise, message, modal]); // <-- Have to pass in [] here!

  function validEmail(value) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
  }

  return (
    <Container className="Contact">
      <div className="content">
        <div className="left-content" sm="3" xs="0">
          <div className="title color-lightblue">Passez à l'action</div>
          <div className="subtitle color-blue">contactez-nous</div>
          <div className="text color-grey">Assistance</div>
          <div className="subtext color-grey">
            - Nous déterminons ensemble vos besoins.
            <br />
            - Nous paramétrons votre compte.
            <br />
            - Nous vous formons gratuitement aux logiciels.
            <br />
            - Nous vous accompagnons vous et vos équipes.
            <br />
          </div>
          <div className="subtext-mail color-grey">
            <a href="mailto:info@leco-france.eu">info@leco-france.eu</a>
          </div>
          <div className="subtext-tel">
            <a href="tel:+33187663412">
              <img src={IconPhoneLeco} alt="phone-leco" />
            </a>
          </div>
        </div>

        <Form className="right-content" sm="7">
          <div className="form-content">
            <div className="text-item">
              <div className="item">
                <FormGroup>
                  <Label for="nomPrenom">Nom Prénom</Label>
                  <Input
                    type="text"
                    name="nomPrenom"
                    id="nomPrenom"
                    placeholder=""
                    value={nomPrenom}
                    onChange={(event) => {
                      setNomPrenom(event.target.value);
                      setNomPrenomInvalid(!nomPrenom);
                    }}
                    invalid={nomPrenomInvalid}
                  />
                </FormGroup>
              </div>
              <div className="item">
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder=""
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setEmailInvalid(!email || !validEmail(email));
                    }}
                    invalid={emailInvalid}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="text-item">
              <div className="item">
                <FormGroup>
                  <Label for="entreprise">Entreprise</Label>
                  <Input
                    type="text"
                    name="entreprise"
                    id="entreprise"
                    placeholder=""
                    value={entreprise}
                    onChange={(event) => setEntreprise(event.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="item">
                <FormGroup>
                  <Label for="telephone">Téléphone</Label>
                  <Input
                    type="tel"
                    name="telephone"
                    id="telephone"
                    placeholder=""
                    value={tel}
                    onChange={(event) => setTel(event.target.value)}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="text-item">
              <FormGroup style={{ width: "100%" }}>
                <Label for="message">Message</Label>
                <Input
                  style={{ height: "10rem" }}
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder=""
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                    setMessageInvalid(!message);
                  }}
                  invalid={messageInvalid}
                />
              </FormGroup>
            </div>
          </div>
          <div className="form-butt">
            <img
              className="img-connexion"
              src={IconConnexion}
              alt="icon-connxion"
              onClick={(event) => {
                setSendRequest(true);
              }}
            />
          </div>
        </Form>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>Le message a bien été envoyé.</ModalBody>
      </Modal>
    </Container>
  );
}

export default Contact;
