import React, { useEffect, useRef } from "react";

import "../styles/MergePages.css";
import Tarif from "./Tarif";
import Contact from "./Contact";
import QuiSommesNous from "./QuiSommesNous";
import Partenaires from "./Partenaires";
import Footer from "./Footer";

const MergePages = (props) => {
  const { showMentions, setShowMentions, showCEE, setShowCEE } = props;

  return (
    <div className="merge-pages">
      <Tarif id="tarif" showCEE={showCEE} setShowCEE={setShowCEE} />
      <div id="contact" className="wrapper">
        <Contact />
      </div>
      <div id="quisommesnous" className="wrapper">
        <QuiSommesNous id="quisommesnous" />
      </div>
      <Partenaires />
      <Footer showMentions={showMentions} setShowMentions={setShowMentions} />
    </div>
  );
};

export default MergePages;
