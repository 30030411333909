import '../styles/GoTop.css'; 
import GoTopIcon from "../assets/icones/goTop.png"

const GoTop = (props) => {
    return (
      <>
      {props.showGoTop && (
        <div className="scroll-to-top" onClick={props.scrollUp}>
          <button className="to-top-button">
            <img src={GoTopIcon} alt="back-to-top-button"/>
          </button>
        </div>

      )}
      </>
    );
  };
  export default GoTop;