import React from "react";
// CSS
import "../styles/MobItem.css";

/*
 index: 3,
    title: "L'APPLIL",
    subTitle: "Conducteur",
    headerColor: "green",
    icon: "",
    text: "Récompoensez la performance",
    */
const MobItem = (props) => {
  const { data } = props;
  return (
    <div className="mobItem">
      <div className="icon">{data.icon}</div>
      <div className="text-content">
        <div className="title color-grey">{data.title}</div>
        <div
          className="text color-grey"
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      </div>
    </div>
  );
};

export default MobItem;
