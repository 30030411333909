import "../styles/Connexion.css";

import IconConnexion from "../assets/icones/icon_go.png";

import { Container, Input, Form, Button } from "reactstrap";

import React, { useState } from "react";
import { Link } from "react-router-dom";

function Connexion() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const redirectToAlixor = (event) => {
    if (userName && password) {
      window.location.href =
        "http://app.leco-france.eu?username=" +
        userName +
        "&password=" +
        password;
      /*window.location.href =
        "https://maxis-eu.com?username=" + userName + "&password=" + password;*/
    }
  };

  return (
    <Container className="connexion">
      <div className="content">
        <div className="title color-orange">Connexion</div>
        <div className="connexion-item">
          <Input type="hidden" name="_csrf_token" />
          <div sm="3" className="input-item">
            <div className="connexion-label  color-grey">Identifiant</div>
            <div>
              <Input
                type="text"
                id="username"
                name="_username"
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
                placeholder=""
              />
            </div>
          </div>
          <div sm="3" className="input-item">
            <div className="connexion-label color-grey">Mot de passe</div>
            <div>
              <Input
                type="password"
                id="password"
                name="_password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder=""
              />
            </div>
          </div>
          <div className="img-content">
            <img
              className="img-connexion"
              onClick={redirectToAlixor}
              src={IconConnexion}
              alt="icon-connxion"
            />
          </div>
        </div>
        {/*<div className="perte-id">
          Perte d'identifiants ? <Link to="#contact">contactez-nous !</Link>
        </div>*/}
        <div className="copyright">
          Logiciels protégés par copyright international {/*&#174; */}&#169;
        </div>
      </div>
    </Container>
  );
}

export default Connexion;
