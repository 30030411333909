
import '../styles/CookieConsent.css';

import React, { useState } from 'react';

import {
    Container, Row, Col, Modal, Button, ModalBody, ModalFooter, ModalHeader
  } from 'reactstrap';

export function CookieConsent({message, buttonLabel, logoSrc,showMentions, setShowMentions}) {

    const cookieName = 'displayCookieConsent';
    
    // Modal open state
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    React.useEffect(() => {
        const toggle = () => setModal(!modal);

        if(!modal && !document.cookie.match(new RegExp(cookieName + '=([^;]+)')))
        {
            toggle(true);
        }

        console.log('CookieConsent');
    },[modal]);
    
    function saveUserPreference() {
        // Set the cookie expiry to one year after today.
        var expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        document.cookie = cookieName + '=y; path=/; expires=' + expiryDate.toGMTString();
      }
   function dismissLinkClick()
   {
        saveUserPreference();
        return false;
   }

    return (
        <Modal id="cookieConsentId"
            className="CookieConsent"
            isOpen={modal}
            toggle={toggle}>
            <ModalHeader>Cookies</ModalHeader>
            <ModalBody>
                <Container>
                    <Col>
                        {logoSrc && (
                            <Row style={{width:'80%', margin:'auto'}}>
                                <img src={logoSrc} alt="logo-leco"/>
                            </Row>
                        )}
                        <Row style={{padding:'2rem', margin:'auto'}}>
                            {message}
                        </Row>
                        {setShowMentions && (
                            <Row>
                                <p style={{cursor:'pointer'}}
                                    onClick={(e) => {
                                    setShowMentions(true);
                                }}>Mentions Legales</p>
                            </Row>
                        )}
                    </Col>
                </Container>
            </ModalBody>
            <ModalFooter>
            <Button className="ml-butt-valid" onClick={(event) => {
                dismissLinkClick();
                toggle(false);
                }}>
                {buttonLabel}
            </Button>
            </ModalFooter>
        </Modal>
        );
}


export default CookieConsent;