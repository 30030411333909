import React, { useState } from "react";
// CSS
import "../styles/CrmItem.css";
import { Modal, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const CrmItem = (props) => {
  const { data } = props;
  // Modal open state
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  /*
  color: "",
      headerIcon: <img src={EcranTransporteur} alt="ecran-transporteur" />,
      headertext: "CRM<br/>Transporteur",
      title: "l'Objectif",
      subtitle: "Prenez le contrôle de A à Z",
      checkListTitle: "Une interface simple et rapide",
      checkListItems: [
        "Ciblez facilement vos objectifs d'éco-conduite et de prévention.",
        "Importez automatiquement vos données fleet et vos consommations.",
        "Supervisez l'action des formateurs et analysez vos résultats.",
      ],
      listTitle: "Documents",
      listIcon: <img src={EcranTransporteur} alt="ecran-transporteur" />,
      listItems: [
        "Bilan CO2",
        "Bilan économique",
        "Bilan consommation & prévention",
      ],
    },
    */
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="Modal" centered={true}>
        <ModalBody>
          <div className="modal-container">
            <div className="header">
              <div className="icon">{data?.modal?.headerIcon}</div>
              <div
                className="title"
                style={{ color: data?.color }}
                dangerouslySetInnerHTML={{ __html: data?.modal?.headerText }}
              ></div>
            </div>
            <div className="content">
              <div className="title">{data?.modal?.title}</div>
              <div className="subtitle color-grey">{data?.modal?.subtitle}</div>
              <div className="checkListTitle color-grey">
                {data?.modal?.checkListTitle}
              </div>
              {data?.modal?.checkListItems.map((item, index) => {
                return (
                  <div
                    className="checkListItem color-grey"
                    key={"checkListItem" + index}
                  >
                    <CheckIcon style={{ color: data?.color }} />
                    {item}
                  </div>
                );
              })}
              <div className="listContainer color-grey">
                <div className="listText">
                  <div className="listTitle">{data?.modal?.listTitle}</div>
                  {data?.modal?.listItems.map((item, index) => {
                    return (
                      <div className="listItems" key={"listItems" + index}>
                        {item}
                      </div>
                    );
                  })}
                </div>
                <div className="listIcon">{data?.modal?.listIcon}</div>
              </div>
            </div>
          </div>
          <Button
            onClick={toggle}
            className="button"
            style={{
              background: data?.color,
            }}
          >
            Fermer
          </Button>
        </ModalBody>
      </Modal>
      <div
        className={"crmItem"}
        style={{ background: data.color + "1a" }}
        onClick={toggle}
      >
        <div className="index color-blue">{data.index}</div>
        <div className={"header"} style={{ background: data.color }}>
          <div className="title">{data.title}</div>
          <div className="subtitle color-blue">{data.subTitle}</div>
        </div>
        <div className="icon">{data.icon}</div>
        <div
          className="text color-blue"
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      </div>
    </>
  );
};

export default CrmItem;
