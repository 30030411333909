// CSS
import "../styles/Solution.css";

import { Container } from "reactstrap";

import React, { useState, useEffect } from "react";
import CrmItem from "../components/CrmItem";
import MobItem from "../components/MobItem";

import EcranTransporteur from "../assets/icones/13_icon_ecran_transporteur.png";
import EcranFormateur from "../assets/icones/14_icon_ecran_formateur.png";
import EcranConducteur from "../assets/icones/15_icon_appli_conducteur.png";

import MobIphone from "../assets/icones/7_iPhone.png";
import MobIphoneResp from "../assets/icones/phone_responsive.png";
import MobPodium from "../assets/icones/8_icon_podium.png";
import MobEcoaching from "../assets/icones/9_icon_ecoaching.png";
import MobCatalogue from "../assets/icones/10_icon_catalogue.png";
import MobGPS from "../assets/icones/11_gps_navxl.png";

import IconForm from "../assets/icones/icon_formateur_sans_ombre.png";
import IconCond from "../assets/icones/icon_appli_conducteur_sans_ombre.png";
import IconTrans from "../assets/icones/icon_transporteur_sans_ombre.png";

import IconPouce from "../assets/icones/icon_pouce_fond_vert_conducteur.png";
import IconDocBleu from "../assets/icones/icon_document_fond_bleu_transporteur.png";
import IconDocOrange from "../assets/icones/icon_document_fond_orange_formateur.png";

import FondOndes from "../assets/fonds/onde_haut_et_bas_jpeg.webp";

const crmItemsData = [
  {
    index: 1,
    title: "Le CRM",
    subTitle: "Transporteur",
    color: "#67c2e2", // light blue
    icon: <img src={EcranTransporteur} alt="ecran-transporteur" />,
    text: "Déterminez vos<br/>objectifs",
    modal: {
      headerIcon: <img src={IconTrans} alt="ecran-transporteur" />,
      headerText: "CRM<br/>Transporteur",
      title: "l'Objectif",
      subtitle: "Prenez le contrôle de A à Z",
      checkListTitle: "Une interface simple et rapide",
      checkListItems: [
        "Ciblez facilement vos objectifs d'éco-conduite et de prévention.",
        "Importez automatiquement vos données fleet et vos consommations.",
        "Supervisez l'action des formateurs et analysez vos résultats.",
      ],
      listTitle: "Documents",
      listIcon: <img src={IconDocBleu} alt="doc" />,
      listItems: [
        "Bilan CO2",
        "Bilan économique",
        "Bilan consommation & prévention",
      ],
    },
  },
  {
    index: 2,
    title: "Le CRM",
    subTitle: "Formateur",
    color: "#f3b540",
    icon: <img src={EcranFormateur} alt="ecran-formateur" />,
    text: "Accompagnez vos<br/>conducteurs",
    modal: {
      headerIcon: <img src={IconForm} alt="ecran-transporteur" />,
      headerText: "CRM<br/>Formateur",
      title: "l'Action",
      subtitle: "La plus-value du formateur",
      checkListTitle: "Les indispensabes",
      checkListItems: [
        "Créez vos QCM numériques personnalisés.",
        "Créez vos campagnes de communication par notification.",
        "Echangez simultanément avec le conducteur et le transporteur.",
      ],
      listTitle: "Documents",
      listIcon: <img src={IconDocOrange} alt="doc" />,
      listItems: [
        "Bilan de compétences",
        "Rapport d'intervention",
        "Rapport d'analyse individuel",
      ],
    },
  },
  {
    index: 3,
    title: "L'APPLI",
    subTitle: "Conducteur",
    color: "#b0d14c", //green
    icon: <img src={EcranConducteur} alt="ecran-conducteur" />,
    text: "Récompensez la<br/>performance",
    modal: {
      headerIcon: <img src={IconCond} alt="ecran-transporteur" />,
      headerText: "L'APPLI<br/>Conducteur",
      title: "la Récompense",
      subtitle: "Accompagnez sereinement",
      checkListTitle: "Tout pour le conducteur",
      checkListItems: [
        "Affichez les performances du conducteur.",
        "Informez avec les vidéos de formation écoconduite.",
        "Récompensez en bons d'achats sur plus de 220 enseignes.",
      ],
      listTitle: "Attractivité",
      listIcon: <img src={IconPouce} alt="pouce" />,
      listItems: [
        "Podium participatif",
        "Module d'accompagnement dédié",
        "GPS Marchandise & Voyageur (Option)",
      ],
    },
  },
];

const mobItemsData = [
  {
    title: "Podium participatif",
    icon: <img src={MobPodium} alt="mob-podium" />,
    text: `Motivez vos conducteurs avec le Pied Vert.<br/>
    Classement regroupant les résultats par axe de conduite.`,
  },
  {
    title: "Module E-coaching",
    icon: <img src={MobEcoaching} alt="mob-ecoaching" />,
    text:
      "Accompagnez vos conducteurs avec le module E-coaching. Participez aux échanges avec le formateur et votre conducteur.",
  },
  {
    title: "Catalogue de E-cartes cadeaux",
    icon: <img src={MobCatalogue} alt="mob-catalogue" />,
    text:
      "Récompensez la performance des conducteurs avec un catalogue de bons d'achats envoyés par mail (sur plus de 220 enseignes).",
  },
  {
    title: "Navigation Poids Lourds",
    icon: <img src={MobGPS} alt="mob-navigation" />,
    text:
      "Offrez à vos conducteurs la possibilité de naviguer avec le GPS PL de notre partenaire NavXL (Proposé en option).",
  },
];

function Solution() {
  return (
    <Container className="Solution">
      <div className="content">
        <div
          className="logiciels-content"
          style={{
            background: `url(${FondOndes})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
          }}
        >
          <div className="title">
            <span className="color-lightblue">3 logiciels</span> en 1
          </div>
          <div className="subtitle color-grey">
            Cliquez sur le CRM pour plus d'information.
          </div>
          <div className="crm-list">
            {crmItemsData.map((item, index) => {
              return <CrmItem data={item} key={index} />;
            })}
          </div>
        </div>
        <div
          className="mobilisez-content"
          style={{
            width: "100%",
          }}
        >
          <div className="title width-50p">
            <span className="color-green">Mobilisez</span> vos équipes
          </div>
          <div className="subtitle color-grey width-50p">
            Offrez à vos conducteurs la possiblité de performer.
          </div>
          <div className="mob-list">
            <div className="mob-items">
              {mobItemsData.map((item, index) => {
                return <MobItem data={item} key={index} />;
              })}
            </div>
            <div className="mob-img">
              <img src={MobIphone} alt="mob-iphone" className="mob-img-item" />
              <img
                src={MobIphoneResp}
                alt="mob-iphone"
                className="mob-img-item-resp"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Solution;
