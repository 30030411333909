import "../styles/ReferencedContent.css";

function ReferencedContent({ customeStyle, children, id }) {
  let localStyle = {};

  if (customeStyle !== undefined) {
    localStyle = { ...localStyle, ...customeStyle };
  }
  //console.log(localStyle);
  return (
    <div className="ReferencedContent" id={id} style={localStyle}>
      {children}
    </div>
  );
}

export default ReferencedContent;
